.concert {

    .image {
        @media (max-width:1400px) {
            height: 407px !important;
        }

        @media (max-width:1200px) {
            height: 415px !important;
        }

        @media (max-width:1024px) {
            height: 348px !important;
        }

        @media (max-width:991px) {
            height: 260px !important;
        }

        @media (max-width:768px) {
            height: 260px !important;
        }

        @media (max-width:575px) {
            height: 206px !important;
        }

        @media (max-width:480px) {
            height: 145px !important;
        }

        @media (max-width:350px) {
            height: 116px !important;
        }

        position: unset !important;
        border-radius: 15px;
        width: 100%;
        height: 408px;
    }

    @media screen and (max-width:768px) {
        .listSlider {
            padding: 40px 0px 0 0px;

            section {
                padding: 20px 0px !important;
            }
        }
    }

    .listSlider {
        padding: 10px 0px 0 0px;

        section:nth-child(odd) {
            background-color: var(--background);
        }

        section {
            padding: 50px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.single_video {

    .title {
        padding: 0 0 10px 0;
        font-size: 28px;
        line-height: 34px;
        font-style: normal;
        color: var(--black);
        font-weight: 400;
    }

    .footerPlayer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 5px 0 20px 0;
        width: 100%;

        @media screen and (max-width:768px) {
            .icon {
                margin: 0 15px 8px 0 !important;

                i {
                    font-size: 20px !important;
                }
            }
        }

        @media screen and (max-width:575px) {
            .icon {
                margin: 0 10px 8px 0 !important;

                i {
                    font-size: 18px !important;
                }
            }
        }

        .icon {
            border: none;
            outline: none;
            background: transparent;
            margin: 0 15px 8px 0;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            i {
                font-size: 25px;
                color: var(--black);
            }

            span {
                color: var(--black);
                font-size: 15px;
            }

        }
    }

    .listVideo {
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            padding: 0 30px;

            @media (max-width:425px) {
                padding: 0 !important;
            }

            .sizeBox {
                @media (max-width:769px) {
                    width: calc(33.333333%);
                }

                @media (max-width:479px) {
                    width: calc(50%);
                }

                width: calc(25%);
            }

            h2 {
                margin: 20px 0;
                font-size: 22px;
                color: var(--black);
            }
        }
    }
}

.all {

    .headerpage {
        padding: 30px 0 15px 0;
        flex-wrap: wrap;

        .title {
            h3 {
                font-size: 25px;
                color: var(--black);

                @media(max-width:425px) {
                    font-size: 18px !important;
                }
            }
        }

        .btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 7px;
            background-color: var(--background);
            border-radius: 5px;
            width: 200px;

            @media(max-width:425px) {
                width: 160px !important;
                padding: 3px !important;
            }

            button {
                width: 50%;
                padding: 10px 15px;
                border: none;
                outline: none;
                margin: 0 2px;
                border-radius: 5px;
                background: transparent;
                color: var(--black);
                font-size: 12px;

                @media(max-width:425px) {
                    padding: 10px 9px !important;
                }
            }

            .active {
                background: var(--primary);
                color: var(--white);
            }
        }
    }


    .dropGrop {
        flex-wrap: wrap;

        .drop {
            @media(max-width:425px) {
                width: 100% !important;
            }

            .btndrop {
                background-color: var(--white);
                width: 160px;
                padding: 10px 0;
                border: 1px solid var(--gray);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: var(--black);

                @media(max-width:425px) {
                    width: 100% !important;
                    justify-content: space-between;
                    padding: 10px 10px !important;
                }
            }

            ul {
                transform: translate(0px, 45px) !important;

                @media(max-width:425px) {
                    width: 100% !important;
                }

                li {
                    padding: 5px 5px;
                    color: var(--black);
                }
            }
        }

        .droptow {
            margin: 0 20px;

            @media (max-width:768px) {
                margin: 10px 0px !important;
                width: 100% !important;
            }

            .input {
                width: 400px;
                background-color: var(--white);
                border: 1px solid var(--gray);
                outline: none;
                padding: 10px 10px;
                border-radius: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: var(--black);

                @media (max-width:768px) {
                    width: 100% !important;
                }
            }

            i {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            ul {
                transform: translate(0px, 45px) !important;
                width: 400px;

                @media (max-width:768px) {
                    width: 100% !important;
                }

                li {
                    padding: 5px 5px;
                    color: var(--black);
                }
            }
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}

.foryou {

    h3 {
        padding: 30px 0 15px 0;
        font-size: 25px;
        color: var(--black);

        @media(max-width:425px) {
            font-size: 18px !important;
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}

.suggested {

    h3 {
        padding: 30px 0 15px 0;
        font-size: 25px;
        color: var(--black);

        @media(max-width:425px) {
            font-size: 18px !important;
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}

.pagination {
    padding: 20px 0;

    button.active {
        background: var(--primary) !important;
        color: var(--white) !important;
    }

    .button {
        padding: 5px;
        margin: 0 5px;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--background);
        border-radius: 10px;

        span {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}