.box_artist {

    .image {
        @media(max-width:991px) {
            width: 17vw !important;
            height: 17vw !important;
        }

        @media(max-width:768px) {
            width: 22vw !important;
            height: 22vw !important;
        }

        @media (max-width:479px) {
            width: 40vw !important;
            height: 40vw !important;
        }

        overflow: hidden;
        border-radius: 5%;
        width: 220px;
        height: 220px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

    }

    .nameArtist {
        margin-top: 24px;

        @media screen and (max-width: "768px") {
            h4 {
                font-size: 14px !important;
            }
        }

        h4 {
            font-size: 20px;
            color: var(--black);
            text-align: center;
        }

    }

}