.home {
    padding: 0 10px;
    .image {
        @media (max-width:1400px) {
            height: 407px !important;
        }

        @media (max-width:1200px) {
            height: 415px !important;
        }

        @media (max-width:1024px) {
            height: 348px !important;
        }

        @media (max-width:991px) {
            height: 260px !important;
        }

        @media (max-width:768px) {
            height: 260px !important;
        }

        @media (max-width:575px) {
            height: 206px !important;
        }

        @media (max-width:480px) {
            height: 130px !important;
        }

        @media (max-width:350px) {
            height: 116px !important;
        }

        position: unset !important;
        border-radius: 10px;
        width: 100%;
        height: 408px;
    }

    .section {
        padding: 10px 0;

        .ads {
            @media (max-width:"769px") {
                height: 190px;
            }

            @media (max-width:"426px") {
                height: 140px;
            }

            @media (max-width:"375px") {
                height: 120px;
            }

            @media (max-width:"330px") {
                height: 100px;
            }

            width: 100%;
            background-color: aqua;
            height: 250px;
            border-radius: 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 20px;
            }
        }

        .sizeBox {
            @media (max-width:769px) {
                width: calc(33.333333%);
            }

            @media (max-width:479px) {
                width: calc(50%);
            }

            width: calc(25%);
        }
    }
}