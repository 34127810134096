.HorizontalBoxImage {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;

    .number {
        width: 20px;
        p {
            font-size: 11px;
            margin: 0;
        }
    }

    .imageHorizontal {
        position: relative;
        margin-left: 7px;

        svg {
            display: none;
            position: absolute;
            left: 20%;
            top: 20%;
        }

        img {
            border-radius: 5px;
            width: 50px;
            height: 50px;
        }

        .spinner-grow{
            position: absolute;
            left: 35%;
            top: 35%;
        }
    }

    .title {

        .name {
            p {
                @media (max-width:375px) {
                    font-size: 12px;
                }
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 800;
                font-size: 14px;
                margin-bottom: 6px;
            }
        }

        .artist {
            p {
                @media (max-width:375px) {
                    font-size: 10px;
                }

                color: var(--black)2;
                margin: 0;
                font-weight: 800;
                font-size: 12px;
            }
        }
    }

    .time {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-size: 12px;
            margin: 0;
        }

        i {
            color: var(--primary);
            cursor: pointer;
            font-size: 20px !important;
        }
    }
}

.musicbox {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .avatar {
        .num {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                position: absolute;
                opacity: 0;
            }

            span {
                opacity: 1;
                font-size: 20px;
            }
        }

        .imagenum {
            position: relative;

            .loadering {
                position: absolute;
                right: 35%;
                top: 35%;
            }
        }
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .info {
            @media (max-width:768px) {
                display: grid;
            }

            display: flex;
            width: 100%;

            .name_music {
                @media (max-width:768px) {
                    width: 100%;
                    font-size: 12px;
                }

                display: flex;
                width: 50%;
                padding: 0 20px;
                font-size: 14px;
                line-height: 24px;
                color: var(--black);
            }

            .artict_music {
                @media (max-width:768px) {
                    width: 100%;
                    font-size: 12px;
                }

                display: flex;
                width: 50%;
                padding: 0 20px;
                font-size: 14px;
                line-height: 24px;
                color: var(--black);
            }
        }

        .time {
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                cursor: pointer;
                font-size: 20px;
                opacity: 0;
            }
        }
    }

}