* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none !important;
}

a {
  text-decoration: none;
  color: var(--black);
}

a:hover {
  color: unset !important;
}

body {
  font-size: 14px;
  font-family: "vazir";
  color: var(--black);
  background: var(--white);
  direction: rtl;
  text-align: right;
}

::-webkit-scrollbar {
  display: none;
}

.headercontent {
  padding: 10px 15px;
  border-bottom: 2px solid #0000110d;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--background);
  z-index: 10;

  .title {
    font-size: 20px;
  }

  i {
    font-size: 17px;
  }
}

.footercontent {
  position: relative;

  .footer {
    padding: 15px 20px;
    border-top: 2px solid #0000110d;
    position: fixed;
    background-color: var(--background);
    width: 100%;
    bottom: 0;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0px;
      }

      i {
        font-size: 17px;
        margin-bottom: 5px;
      }
    }

    .active {
      i {
        color: #ef225d;
      }

      p {
        color: #ef225d;
      }
    }
  }
}

.sidebarslide {
  width: .75rem;
  position: fixed;
  background-color: transparent;
  top: 0;
  right: 0;
  height: 100vh;
}

.sidebarcontent {
  width: 100%;
  position: fixed;
  z-index: 101;
  background-color: #00001159;
  top: 0;
  height: 100vh;
  //display: none;
  margin-right: -100%;

  .sidebar {
    width: 70%;
    height: 100vh;
    background-color: var(--background);
    padding: 20px;
    position: fixed;
    margin-right: -70%;
    // transition: margin-right .5s;

    .item {
      padding: 14px 0px;

      i {
        color: var(--black);
      }

      span {
        margin-right: 10px;
      }
    }

    .bordertop {
      border-top: 2px solid #0000110d;
    }
  }
}

.form-control[readonly] {
  background-color: unset !important;
}

.premiere-carousel {
  overflow: hidden;

  .slick-list {
    overflow: visible !important;
    margin: 0 -60px;

    .slick-active::after {
      opacity: 0;
      z-index: -1;
    }

    .slick-slide {
      outline: none !important;
      border: none !important;
      padding: 0 12px;
      position: relative;

      &::after {
        background: #00000088;

        position: absolute;
        left: 12px;
        top: 0;
        right: 12px;
        bottom: 5px;
        content: "";
        display: block;
        opacity: 0.7;
        border-radius: 16px;
        -webkit-transition: all linear 0.3s;
        -o-transition: all linear 0.3s;
        transition: all linear 0.3s;
      }

      .item {
        outline: none !important;
      }
    }
  }

  .slick-next,
  .slick-prev {
    color: transparent;
    outline: none;
    background: transparent;
  }

  .slick-next,
  .slick-prev {
    z-index: 3;
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }

  .slick-next {
    right: -70px !important;
    background: url("../images/next.svg") center no-repeat !important;
  }

  .slick-prev {
    left: -70px;
    background: url("../images/preview.svg") center no-repeat !important;
  }

  .slick-arrow::before {
    content: "" !important;
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;

    .slick-active button {
      opacity: 1;

      &::before {
        font-family: slick;
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: -3px;
        right: -3px;
        background: url("../images/border.svg") top right no-repeat;
        -webkit-animation: circleRotate 2s linear 0s infinite forwards normal;
        animation: circleRotate 2s linear 0s infinite forwards normal;
      }
    }

    button {
      background: #ef225d;
      opacity: 0.4;
      width: 12px;
      height: 12px;
      border: 0;
      outline: 0;
      border-radius: 50%;
      text-indent: -9999px;
      white-space: nowrap;
      cursor: pointer;
      position: relative;
    }

    @keyframes circleRotate {
      from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
      }

      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}

.image_resize {
  @media (max-width: 1400px) {
    height: 180px !important;
  }

  @media (max-width: 1200px) {
    height: 145px !important;
  }

  @media (max-width: 1024px) {
    height: 203px !important;
  }

  @media (max-width: 991px) {
    height: 140px !important;
  }

  @media (max-width: 768px) {
    height: 137px !important;
  }

  @media (max-width: 575px) {
    height: 151px !important;
  }

  @media (max-width: 480px) {
    height: 38.5vw !important;
  }

  @media (max-width: 350px) {
    height: 135px !important;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 175px;
  position: relative;
}

.Toastify__toast-container {
  @media (max-width: 600px) {
    width: 100% !important;
  }

  z-index: 10000000000000 !important;
  width: 270px !important;

  .Toastify__toast {
    min-height: unset !important;
    font-family: "vazir";
  }

  .Toastify__close-button {
    align-self: unset !important;
  }
}

.main-content {
  padding-top: 50px;
  padding-bottom: 180px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.banner-slider {
  .splide__arrows--rtl {
    @media screen and (max-width: "768px") {
      .splide__arrow {
        width: 2em !important;
        height: 2em !important;
      }
    }

    .splide__arrow {
      background-color: #4c4c4c !important;
      width: 3em !important;
      height: 3em !important;

      svg {
        fill: white !important;
      }
    }

    .splide__arrow--next {
      left: 1em !important;
    }

    .splide__arrow--prev {
      right: 1em !important;
    }
  }

  .splide__arrows--rtl {
    @media screen and (max-width: "768px") {
      .splide__arrow--next,
      .splide__arrow--prev {
        width: 2.3em !important;
        height: 2.3em !important;
      }
    }
  }
}

.splide__arrows--rtl {
  @media screen and (max-width: "768px") {
    .splide__arrow {
      width: 2em !important;
      height: 2em !important;
    }
  }

  .splide__arrow {
    background-color: #4c4c4c !important;
    width: 3em !important;
    height: 3em !important;

    svg {
      fill: white !important;
    }
  }

  .splide__arrow--next {
    left: -1em !important;
  }

  .splide__arrow--prev {
    right: -1em !important;
  }
}

.splide__arrows--rtl {
  @media screen and (max-width: 768px) {
    .splide__arrow--next,
    .splide__arrow--prev {
      width: 2.3em !important;
      height: 2.3em !important;
    }
  }

  @media screen and (max-width: 425px) {
    .splide__arrow--next,
    .splide__arrow--prev {
      display: none;
    }
  }
}

.without-icon {
  &::after {
    display: none;
  }

  &::before {
    display: none;
  }
}

.video-react-has-started .video-react-control-bar {
  display: block !important;
}

.video-react .video-react-time-control,
.video-react .video-react-play-control,
.video-react .video-react-volume-menu-button,
.video-react .video-react-fullscreen-control {
  display: none !important;
}

.video-react .video-react-progress-control {
  width: 100% !important;
  position: absolute !important;
  top: 10px !important;
  height: unset !important;
}

.video-react .video-react-control-bar {
  height: unset !important;
  background-color: transparent !important;
  padding: 15px 0px 0px 0px !important;
}

.video-react .video-react-play-progress {
  background-color: var(--primary) !important;
}

.video-react .video-react-progress-holder .video-react-play-progress {
  height: 0.7em !important;
}

.video-react .video-react-play-progress:before {
  top: -1px !important;
}

.video-react .video-react-progress-holder .video-react-load-progress div {
  height: 0.7em !important;
}

.video-react .video-react-progress-holder {
  height: 0.7em !important;
}

.video-react .video-react-big-play-button,
.video-react .video-react-big-play-button:before {
  display: none !important;
}

.video-react .video-react-poster {
  background-color: var(--black3) !important;
}

.video-react .video-react-loading-spinner:before,
.video-react .video-react-loading-spinner:after {
  margin: -6px -45px !important;
}

.video-react {
  z-index: 10000;
}

.accordion-button::after {
  width: unset !important;
  height: unset !important;
  margin-right: unset !important;
  content: unset !important;
  background-image: unset !important;
  background-repeat: unset !important;
  background-size: unset !important;
}

.accordion-button {
  padding: unset !important;
  background-color: transparent !important;
  color: unset !important;
}

.accordion-button:focus {
  border: unset !important;
  box-shadow: unset !important;
  outline: unset !important;
}

.accordion-button:not(.collapsed) {
  color: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.accordion-item {
  background-color: transparent !important;
  border: none !important;
}

.nav-link {
  color: var(--black);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--light) !important;
  color: unset !important;
}

// input[type=range] {
//     accent-color: var(--primary);
// }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .sliderPlayer {
    overflow: hidden;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    direction: ltr;
    -webkit-appearance: none;
    background-color: transparent;
  }

  .sliderPlayer::-webkit-slider-runnable-track {
    height: 100%;
    -webkit-appearance: none;
    color: transparent;
    margin-top: -1px;
  }

  .sliderPlayer::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 100%;
    cursor: pointer;
    background: var(--primary);
    box-shadow: -100vw 0 0 100vw var(--primary);
  }
}

/** FF*/
.sliderPlayer::-moz-range-progress {
  background-color: var(--primary);
}

.sliderPlayer::-moz-range-track {
  background-color: transparent;
}

/* IE*/
.sliderPlayer::-ms-fill-lower {
  background-color: var(--primary);
}

.sliderPlayer::-ms-fill-upper {
  background-color: transparent;
}

.drops {
  .dropup {
    .dropdown-menu {
      justify-content: center;
      align-items: center;
      background-color: var(--ccc);
      transform: translate3d(-50%, -31px) !important;

      p {
        padding: 5px 7px;
        margin: 0;
        font-size: 14px;
        text-align: center;
        color: var(--black);
      }

      .signIn {
        background: var(--primaryGradient);
        padding: 5px 15px;
        border-radius: 10px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          color: #fff;
        }
      }
    }
  }
}

.share {
  .dropdown {
    ul {
      @media (max-width: 425px) {
        transform: translate(-46%, 30px) !important;
      }

      min-width: 11rem !important;
      background-color: var(--ccc);
      transform: translate(-46%, 35px) !important;
      position: relative;

      .icon {
        width: 20px;
        height: 20px;
        background-color: var(--ccc) !important;
        transform: rotate(45deg);
        position: absolute;
        top: -10px;
        right: 37%;
      }

      .items {
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      button {
        background-color: var(--primary);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        border-radius: 5px;

        i {
          color: #fff !important;
          font-size: 16px !important;
          transform: rotate(45deg);
        }
      }

      p {
        font-size: 14px;
        color: var(--black);
      }
    }
  }
}

.modal-content {
  max-width: 440px;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1120px;
  }
}

.row > * {
  padding-left: calc(var(--bs-gutter-x) * 0.12) !important;
  padding-right: calc(var(--bs-gutter-x) * 0.12) !important;
}

.loader {
  @media (max-width: 1400px) {
    height: 407px !important;
  }

  @media (max-width: 1200px) {
    height: 415px !important;
  }

  @media (max-width: 1024px) {
    height: 348px !important;
  }

  @media (max-width: 991px) {
    height: 260px !important;
  }

  @media (max-width: 768px) {
    height: 260px !important;
  }

  @media (max-width: 575px) {
    height: 206px !important;
  }

  @media (max-width: 480px) {
    height: 170px !important;
  }

  @media (max-width: 350px) {
    height: 122px !important;
  }

  height: 408px;
}

.loaderTow {
  @media (max-width: 1400px) {
    height: 255px !important;
  }

  @media (max-width: 1200px) {
    height: 210px !important;
  }

  @media (max-width: 991px) {
    height: 150px !important;
  }

  @media (max-width: 768px) {
    height: 198px !important;
  }

  @media (max-width: 767px) {
    height: 148px !important;
  }

  @media (max-width: 575px) {
    height: 145px !important;
  }

  @media (max-width: 480px) {
    height: 36vw !important;
  }

  @media (max-width: 350px) {
    height: 135px !important;
  }

  height: 250px;
}

.loaderTree {
  @media (max-width: 991px) {
    height: 100px !important;
  }

  @media (max-width: 768px) {
    height: 121px !important;
  }

  @media (max-width: 575px) {
    height: 100px !important;
  }

  @media (max-width: 350px) {
    height: 80px !important;
  }

  height: 145px;
}

.loaderFure {
  height: 40px;
}

.loaderFive {
  @media (max-width: 1400px) {
    height: 180px !important;
  }

  @media (max-width: 1200px) {
    height: 145px !important;
  }

  @media (max-width: 1024px) {
    height: 203px !important;
  }

  @media (max-width: 991px) {
    height: 140px !important;
  }

  @media (max-width: 768px) {
    height: 137px !important;
  }

  @media (max-width: 575px) {
    height: 151px !important;
  }

  @media (max-width: 480px) {
    height: 38.5vw !important;
  }

  @media (max-width: 350px) {
    height: 135px !important;
  }

  height: 175px;
}

.loaderSix {
  @media (max-width: 769px) {
    width: calc(33.333333%);
  }

  @media (max-width: 575px) {
    height: 120px !important;
  }

  @media (max-width: 479px) {
    width: calc(50%);
  }

  height: 145px;
  width: calc(25%);
}

.loaderSeven {
  width: 200px;
  height: 200px;
}

.loaderEight {
  @media (max-width: 1200px) {
    height: 160px;
  }

  @media (max-width: 1024px) {
    height: 160px;
  }

  @media (max-width: 991px) {
    height: 124px;
  }

  @media (max-width: 769px) {
    width: calc(33.333333%);
    height: 165px;
  }

  @media (max-width: 575px) {
    height: 30.5vw;
  }

  @media (max-width: 479px) {
    width: calc(50%);
    height: 45vw;
  }

  height: 192px;
  width: calc(25%);
}

.bars {
  margin: 8px 0;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 6px;
  border-radius: 3px;
  background: #dfe1f0;

  div {
    height: 6px;
    border-radius: 3px;
    transition: 0.4s;
    width: 0%;
  }
}

.weak {
  .level {
    background: #e24c71;
    width: 33.33%;
  }
}

.medium {
  .level {
    background: #f39845;
    width: 66.66%;
  }
}

.strong {
  .level {
    background: #57c558;
    width: 100%;
  }
}

.strength {
  text-align: right;
  height: 30px;
  text-transform: capitalize;
  color: #868b94;
}
