.videoPlayer {
    &.minimize {
        position: fixed;
        right: 0;
        width: 100%;
        height: 75px;
        background: var(--background);
        color: var(--black);
        z-index: 20;

        .video-react {
            width: 150px !important;
            height: 75px !important;
        }

        .video-react-loading-spinner {
            display: none !important;
        }

        .video-react-control-bar {
            display: none !important;
        }
    }

    .ti-close {
        font-size: 20px;
        margin-left: 10px;
    }


    .controlBar {
        display: flex !important;
        width: 100% !important;
        background-color: #000;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 7px 4px;

        @media screen and (max-width:"991px") {
            .controlPlay {
                padding: 10px;
            }
        }

        @media screen and (max-width:"768px") {
            .controlPlay {
                padding: 8px !important;

                i {
                    font-size: 20px !important;
                }

                svg {
                    width: 25px !important;
                    height: 25px !important;
                }
            }
        }

        @media screen and (max-width:"575px") {
            .controlPlay {
                padding: 6px !important;

                i {
                    font-size: 17px !important;
                }

                svg {
                    width: 22px !important;
                    height: 22px !important;
                }
            }
        }

        input {
            width: 100%;
            border-radius: 5px;
            display: none;
        }

        .controlPlay {
            padding: 10px;
            background: #000;
            border: none;
            outline: none;

            i {
                font-size: 25px;
                color: #fff;
                transition: all 0.3s;
            }

            svg {
                width: 30px;
                height: 30px;
                transition: all 0.3s;
            }

        }

    }

    .dropSetting {

        ul {
            @media (max-width:768px) {
                transform: translate3d(-10px, -90px, 0px) !important;
                padding: 5px !important;
                min-width: 100px;

                li {
                    font-size: 13px !important;
                    font-weight: 500 !important;
                    line-height: 8px !important;
                    padding: 5px 7px !important;

                    .item {
                        font-size: 13px !important;
                        padding: 3px 7px !important;
                    }

                    .list {
                        top: -70px !important;
                        left: -73px !important;
                    }

                    .listTow {
                        top: -5px !important;
                        left: -55px !important;
                    }
                }
            }

            @media (max-width:350px) {
                transform: translate3d(-5px, -80px, 0px) !important;

            }

            inset: unset !important;
            transform: translate3d(-10px, -125px, 0px) !important;
            padding:10px 3px;
            background: var(--ccc);
            border-radius: 10px;

            li {
                font-size: 17px;
                font-weight: 400;
                font-family: 'vazir';
                line-height: 10px;
                padding: 7px 10px;
                cursor: pointer;
                color: #000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 10px;

                .item {
                    color: var(--black);
                    font-size: 15px;
                    font-weight: 400;
                    font-family: 'vazir';
                    line-height: 10px;
                    padding: 7px 10px;
                    justify-content: flex-end;
                    display: flex;
                    border-radius: 10px;
                }

                .list {
                    background-color: var(--ccc);
                    position: absolute;
                    left: -58%;
                    top: -120%;
                    padding: 7px;
                    border-radius: 10px;
                    transition: all .5s;
                    opacity: 0;
                    visibility: hidden;

                    &.active {
                        opacity: 1;
                        visibility: unset;
                        z-index: 1;
                    }
                }

                .listTow {
                    background-color: var(--ccc);
                    position: absolute;
                    left: -75%;
                    top: 0;
                    padding: 7px;
                    border-radius: 10px;
                    transition: all .5s;
                    opacity: 0;
                    visibility: hidden;

                    &.active {
                        opacity: 1;
                        visibility: unset;
                        z-index: 2;
                    }
                }



            }

        }
    }

}

.videoplayer-mini {
    position: fixed;
    bottom: 73px;
    left: 0px;
    width: 100%;
    padding: 10px;
    background: var(--background);

    video {
        width: 30vw;
        height: auto;
        object-fit: cover;
    }
}