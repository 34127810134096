.Artist {
    .imagecont {
        @media (min-width:769px) {
            height: 395px;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-attachment: fixed !important;
        background-position: top !important;
        background-repeat: no-repeat !important;
        height: 352px;

        .gradients {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-image: var(--primaryGradient2);
        }

        .fullname {
            position: absolute;
            bottom: 5px;
            font-size: 20px;
            line-height: 17px;
            font-weight: 700;
            color: var(--black);
            z-index: 3;
        }

    }

    .cont {
        margin-bottom: 100px;

        .lastmusic {

            .image_music {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                background-color: var(--ccc);
                border-radius: 15px;

                .img {
                    @media (min-width:768px) {
                        width: 150px;
                        height: 150px;
                    }

                    border-radius: 15px;
                    object-fit: fill;
                    width: 120px;
                    height: 120px;
                }

                .info {
                    @media (min-width:375px) {
                        padding: 0 35px;
                    }

                    margin: 7px;
                    padding: 0 10px;

                    .songname {
                        text-align: center;
                        font-size: 22px;
                        line-height: 30px;
                        margin: 0;
                        cursor: pointer;
                    }
                }

            }

        }

        .sizeBox {
            @media (max-width:769px) {
                width: calc(33.333333%);
            }

            @media (max-width:479px) {
                width: calc(50%);
            }

            width: calc(25%);
        }
    }
}

.MusicList {
    padding-top: 35px;

    .headerMyMusic {
        @media (max-width:768px) {
            justify-content: space-between;
            padding: 0 40px;
        }

        padding: 0 80px;
        justify-content: space-between;

        span {
            font-size: 15px;
            color: var(--black);

            i {
                font-size: 20px;
            }
        }
    }

    .musicbox {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;

        .avatar {
            .num {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    position: absolute;
                    opacity: 0;
                }

                span {
                    opacity: 1;
                    font-size: 20px;
                }
            }
        }

        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .info {
                @media (max-width:768px) {
                    display: grid;
                }

                display: flex;
                width: 100%;

                .name_music {
                    @media (max-width:768px) {
                        width: 100%;
                        font-size: 12px;
                    }

                    display: flex;
                    width: 50%;
                    padding: 0 20px;
                    font-size: 14px;
                    line-height: 24px;
                    color: var(--black);
                }

                .artict_music {
                    @media (max-width:768px) {
                        width: 100%;
                        font-size: 12px;
                    }

                    display: flex;
                    width: 50%;
                    padding: 0 20px;
                    font-size: 14px;
                    line-height: 24px;
                    color: var(--black);
                }
            }

            .time {
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    cursor: pointer;
                    font-size: 20px;
                    opacity: 0;
                }
            }
        }

    }
}

.Concerts {
    padding-top: 35px;

    .title {
        font-size: 22px;
        line-height: 28px;
    }

    .singer {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--gray);
        cursor: pointer;
    }

}

.videos {
    padding-top: 35px;

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}

.Albums {
    margin-top: 100px;
}