.item_video {
  display: grid;
  padding: 0 5px;
  width: 100%;

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .lable_header {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .lable_left {
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          position: absolute;
          top: 0;
          left: 0;
          background-color: var(--primary);
          border-top-left-radius: 15px !important;
          border-radius: 5px;
          padding: 3px 10px;

          p {
            color: white;
            font-size: 13px;
          }
        }

      }

      @media screen and (max-width:"768px") {
        .lable_right {
          padding: 3px 5px !important;

          svg {
            height: 20px;
            width: 20px;
          }
        }
      }

      .lable_right {
        z-index: 3;
        cursor: pointer;
        padding: 3px 10px;
        display: none;

        svg {
          height: 30px;
          width: 30px;
          fill: none;
        }
      }

    }

    .image_video {
      @media (max-width:991px) {
        height: 100px !important;
      }

      @media (max-width:768px) {
        height: 121px !important;
      }

      @media (max-width:575px) {
        height: 85px !important;
      }


      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 145px;
      position: relative;

      img {
        border-radius: 10px;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .icon_video {
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: rgb(34, 34, 34, 0.5);
        border-radius: 15px;
        z-index: 2;
        position: absolute;

        a {
          @media screen and (max-width:"991px") {
            svg {
              width: 45px !important;
              height: 45px !important;
            }
          }

          svg {
            z-index: 4;
            width: 60px;
            height: 60px;
            transition: all 0.5s;
          }

        }
      }

    }

    .lable_footer {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 5px 10px;
      z-index: 3;

      p {
        color: #fff;
      }
    }
  }

  .item_description {
    direction: rtl;
    padding: 0 12px;

    @media screen and (max-width:"768px") {
      a {
        font-size: 13px !important;
      }
    }

    a {
      color: var(--black);
      text-decoration: none;
      font-style: normal;

    }

    .name_video {
      a {
        span {
          color: var(--black);
          font-weight: 600;
          line-height: 28px;
          font-size: 11px;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .artist_video {
      a {
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: var(--black2);
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}