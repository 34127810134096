 .Category {

     .header {
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-bottom: 10px;

         .title {
             font-weight: 600;
             font-size: 17px;
             line-height: 24px;
             margin: 0;
         }

         .sub {
             @media (max-width:425px) {
                 font-size: 12px;
             }

             font-style: normal;
             font-weight: 400;
             font-size: 15px;
             line-height: 24px;
             color: #828282;
         }

         .more {
             display: flex;
             align-items: center;
             justify-content: center;

             p {
                 margin: 0 7px;
             }
         }


     }
 }