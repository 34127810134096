.CategoryBox {
    display: flex;
    flex-wrap: wrap;

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }
        width: calc(25%);
    }
}