.music {
    margin-bottom: 100px;
    padding: 105px 10px 0 10px;

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}

.singleMusic {
    margin-bottom: 100px;
    padding: 0 10px;


}

.togglebtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px;
    background-color: var(--background);
    border-radius: 5px;
    width: 200px;

    @media(max-width:425px) {
        width: 160px !important;
        padding: 3px !important;
    }

    button {
        width: 50%;
        padding: 10px 15px;
        border: none;
        outline: none;
        margin: 0 2px;
        border-radius: 5px;
        background: transparent;
        color: var(--black);
        font-size: 12px;

        @media(max-width:425px) {
            padding: 7px 15px !important;
        }
    }

    .active {
        background: var(--primary);
        color: var(--white);
    }
}

.albumsMusic {
    padding-top: 60px;
    margin-bottom: 200px;

    .sectiontitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 28px;
        }

        @media (max-width: 1200px) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }

    .contentSingle {
        .imagecont {
            @media (min-width:769px) {
                height: 395px;
                background-size:395px auto !important;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-attachment: fixed !important;
            background-position: top !important;
            background-repeat: no-repeat !important;
            background-size:352px auto !important;
            height: 352px;

            .gradients {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-image: var(--primaryGradient2);
            }

            .namealbum {
                position: absolute;
                bottom: 45px;
                font-size: 20px;
                line-height: 17px;
                font-weight: 700;
                color: var(--black);
                z-index: 3;
            }

            .namesinger {
                position: absolute;
                bottom: 15px;
                font-size: 17px;
                line-height: 15px;
                font-weight: 500;
                color: var(--black);
                z-index: 3;
            }

            .btngroup {
                z-index: 3;
                padding: 0 15px;
                width: 100%;
                position: absolute;
                bottom: -30px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .btnshare,
                .btnlike {
                    border: none;
                    outline: none;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    background: var(--gray);
                    color: var(--white);
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .btnplay {
                    padding: 0 30px;
                    border: none;
                    outline: none;
                    border-radius: 50px;
                    height: 40px;
                    background: var(--primary);
                    color: var(--white);
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        padding: 0 10px;
                        font-size: 16px;
                    }
                }
            }

        }

        .details {
            margin-right: 25px;

            .year {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: .15px;
            }

            .name {
                font-size: 34px;
                line-height: 40px;
                letter-spacing: .25px;
                margin-bottom: 11px;
            }

            .info {
                margin-bottom: 10px;
            }

            @media (max-width: 475px) {
                .name {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            @media (max-width: 575px) {
                .name {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

            @media (max-width: 768px) {
                .name {
                    font-size: 26px;
                    line-height: 32px;
                }
            }

            @media (max-width: 991px) {
                .name {
                    font-size: 28px;
                    line-height: 34px;
                }
            }

            .light {
                color: var(--gray);
                font-size: 16px;
                line-height: 24px;
                letter-spacing: .44px;
            }

            @media (max-width: 991px) {
                .light {
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            .singer {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: .44px;
            }

            .icons {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .item {
                    width: 32px;
                    height: 32px;
                    border: 1px solid var(--gray);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }

            .iconsTow {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .playBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--primary);
                    border: 1px solid var(--primary);
                    border-radius: 8px;
                    padding: 10px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: .75px;
                    text-transform: uppercase;
                    transition: all .2s ease;
                    color: white;

                    i {
                        color: white;
                    }
                }

                .item {
                    i {
                        font-size: 25px;
                        cursor: pointer;
                    }
                }
            }
        }

        @media (max-width: 480px) {
            .details {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 0;
                margin-top: 20px;
            }
        }

    }
}

.allMusic {
    padding-top: 60px;
    margin-bottom: 200px;

    .sectiontitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 28px;
        }

        @media (max-width: 1200px) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}

.suggestedMusic {
    padding-top: 60px;
    margin-bottom: 200px;

    .sectiontitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 28px;
        }

        @media (max-width: 1200px) {
            font-size: 22px;
            line-height: 30px;
        }
    }
}

.singersMusic {
    padding-top: 60px;
    margin-bottom: 200px;

    .sectiontitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 28px;
        }

        @media (max-width: 1200px) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}

.categories {
    padding-top: 60px;
    margin-bottom: 200px;

    .sectiontitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 28px;
        }

        @media (max-width: 1200px) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }

    .contentSingle {
        .imagecont {
            @media (min-width:769px) {
                height: 395px;
                background-size:395px 395px !important;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-attachment: fixed !important;
            background-position: top !important;
            background-repeat: no-repeat !important;
            background-size:352px 352px !important;
            height: 352px;

            .gradients {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-image: var(--primaryGradient2);
            }

            .namealbum {
                position: absolute;
                bottom: 45px;
                font-size: 20px;
                line-height: 17px;
                font-weight: 700;
                color: var(--black);
                z-index: 3;
            }

            .namesinger {
                position: absolute;
                bottom: 15px;
                font-size: 17px;
                line-height: 15px;
                font-weight: 500;
                color: var(--black);
                z-index: 3;
            }

            .btngroup {
                z-index: 3;
                padding: 0 15px;
                width: 100%;
                position: absolute;
                bottom: -10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .btnshare,
                .btnlike {
                    border: none;
                    outline: none;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    background: var(--gray);
                    color: var(--white);
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .btnplay {
                    padding: 0 30px;
                    border: none;
                    outline: none;
                    border-radius: 50px;
                    height: 40px;
                    background: var(--primary);
                    color: var(--white);
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        padding: 0 10px;
                        font-size: 16px;
                    }
                }
            }

        }

        .details {
            margin-right: 25px;

            .year {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: .15px;
            }

            .name {
                font-size: 34px;
                line-height: 40px;
                letter-spacing: .25px;
                margin-bottom: 11px;
            }

            .info {
                margin-bottom: 10px;
            }

            @media (max-width: 475px) {
                .name {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            @media (max-width: 575px) {
                .name {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

            @media (max-width: 768px) {
                .name {
                    font-size: 26px;
                    line-height: 32px;
                }
            }

            @media (max-width: 991px) {
                .name {
                    font-size: 28px;
                    line-height: 34px;
                }
            }

            .light {
                color: var(--gray);
                font-size: 16px;
                line-height: 24px;
                letter-spacing: .44px;
            }

            @media (max-width: 991px) {
                .light {
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            .singer {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: .44px;
            }

            .icons {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .item {
                    width: 32px;
                    height: 32px;
                    border: 1px solid var(--gray);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }

            .iconsTow {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .playBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--primary);
                    border: 1px solid var(--primary);
                    border-radius: 8px;
                    padding: 10px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: .75px;
                    text-transform: uppercase;
                    transition: all .2s ease;
                    color: white;

                    i {
                        color: white;
                    }
                }

                .item {
                    i {
                        font-size: 25px;
                        cursor: pointer;
                    }
                }
            }
        }

        @media (max-width: 480px) {
            .details {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 0;
                margin-top: 20px;
            }
        }

    }
}

.superiorMusic {
    padding-top: 60px;
    margin-bottom: 200px;

    .sectiontitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 28px;
        }

        @media (max-width: 1200px) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}