.Suggest {
    padding: 52px 0 75px 0;
    display: flex;
    flex-wrap: wrap;

    .sizeBox {
       
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);

        i {
            color: #fff;
            font-size: 45px;
        }
    }
}