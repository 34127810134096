.account {
    .item {
        padding: 10px 0px;

        span {
            margin-right: 10px;
            font-size: 15px;
        }
    }
}

.borderb {
    border-bottom: 2px solid var(--line);
}

.lighttext {
    font-size: 10px;
    color: var(--gray);
    direction: ltr;
}

.circle {
    border-radius: 100%;
    border-color: var(--primary);
    border-width: 2px;
    border-style: solid;
    background-color: #c4c4c4;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 25px;
}

.logoutbtn {
    width: 100%;
    background-color: var(--gray);
    outline: none;
    border: none;
    padding: 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    .trans {
        transform: rotate(0.25turn);
    }
}

.input {
    width: 100%;
    background-color: transparent;
    border: 2px solid var(--ccc);
    border-radius: 5px;
    color: var(---black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 9px;

    input {
        padding: 9px;
        outline: none;
        background-color: transparent;
        width: 90%;
        border: none;
    }
}

.editbtn {
    width: 100%;
    padding: 12px;
    background-color: var(--primary);
    color: #fff;
    outline: none;
    border: none;
    border-radius: 5px;

}

.themeimg {
    width: 100%;
    border: 1.9px solid var(--ccc);
    border-radius: 7px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
}

.themeactive {
    border: 1.9px solid var(--primary) !important;
}

.upgrade {
    h2 {
        padding: 10px;
        font-size: large;
        line-height: 35px;
    }

    .national {
        @media (max-width:768px) {
            width: 90%;
            margin: 50px auto;
        }

        position: relative;
        margin: 50px auto 0 auto;
        width: 70%;
        height: 200px;
        border-radius: 15px;
        border: 1px dashed #888;
        overflow: hidden;

        p {
            font-size: small;
            margin: auto;
            position: absolute;
            top: 40%;
            right: 50%;
            transform: translate(50%, 50%);
        }

        input {
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            z-index: 2;
            right: 0;
            top: 0;
        }

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .submit {
        margin: 50px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            border: none;
            outline: none;
            color: #fff;
            background-color: var(--primary);
            padding: 10px 35px;
            border-radius: 10px;
        }
    }
}