.boxPoster {
  position: relative;

  .imagecont {
    display: flex;
    align-items: center;
    justify-content: center;
    background-attachment: fixed !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 320px;

    .gradients {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: var(--primaryGradient2);
    }

    .namealbum {
      position: absolute;
      bottom: 80px;
      font-size: 19px;
      line-height: 17px;
      font-weight: 700;
      color: var(--black);
      z-index: 3;
    }

    .namesinger {
      position: absolute;
      bottom: 50px;
      font-size: 15px;
      line-height: 15px;
      font-weight: 500;
      color: var(--black);
      z-index: 3;
    }
  }

  .btnMovie {
    .btngroup {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;

      button {
        width: 100%;
        border: none;
        outline: none;
        padding: 10px 0;
        border-radius: 5px;
        color: #fff;
        background: var(--primaryGradient);
        font-size: 17px;
        font-weight: 600;
      }
    }

    .goback {
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      margin: 15px;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: var(--ccc);
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 20px;
        color: var(--black);
      }
    }
  }
}

.footerPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 100%;

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      border: none;
      outline: none;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 5px;

      i {
        font-size: 20px;
        color: var(--black);
      }

      span {
        color: var(--black);
        font-size: 15px;
      }
    }
  }

  .informtoin {
    width: 100%;

    p {
      font-size: 13px;
      font-weight: 600;
      color: var(--black);
    }
  }
}
