.audio {
    display: none;
}

body {
    overscroll-behavior-y: contain;
}

playlistModal .footer {
    transition: all .2s;
}

.icon {
    margin: 0px 7px;
    cursor: pointer;
}

svg {
    cursor: pointer;
}

.active {
    fill: var(--primary) !important;
}

.player {

    svg {
        fill: #ffffff !important;
    }

    a {
        color: #ffffff !important;
    }

    .header-mini {
        svg {
            fill: var(--black) !important;
        }

        color: var(--black) !important;
    }

    &.player-dark {
        svg {
            fill: #222222 !important;
        }

        color: #222222 !important;

        .header {
            i {
                color: #222222 !important;
            }
        }

        a {
            color: #222222 !important;
        }

        .main {
            .control-player {

                .fa-bookmark {
                    color: #222222 !important;
                }

                .fa-share-alt {
                    color: #222222 !important;
                }


                .icon-toggle {
                    background: #222222 !important;
                    color: #ffffff !important;

                    svg {
                        fill: #ffffff !important;
                    }
                }
            }

            input[type=range] {
                accent-color: #222222;
            }
        }
    }

    color: #ffffff;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #222222;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: all .2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .layer-backdrop {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000000aa;
        backdrop-filter: blur(200px);
        z-index: 0;
    }

    .header {
        i {
            color: #ffffff;
            font-size: 23px;
        }

        top: 0;
        left: 0;
        width: 100%;
        position: absolute;

    }

    .header-mini {
        top: 0;
        left: 0;
        width: 100%;
        height: 10vh;
        position: absolute;
        background: var(--white);
        z-index: 0;
        opacity: 0;

        .content-mini-player {
            margin-top: 13px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        i {
            color: var(--black);
            font-size: 23px;
        }

        .icon-play {
            margin: 0 10px;
        }
    }

    .main {

        .control-player {

            .fa-bookmark {
                font-size: 25px;
                color: #ffffff;
            }

            .fa-share-alt {
                font-size: 25px;
                color: #ffffff;
            }

            .icon-prv {
                transform: rotate(180deg);
            }

            .icon-toggle {
                width: 50px;
                height: 50px;
                border-radius: 100%;
                background: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #222222;

                svg {
                    fill: #222222 !important;
                }
            }
        }

        .splide__track--draggable {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        input[type=range] {
            accent-color: #ffffff;
          
        }

        @media screen and (-webkit-min-device-pixel-ratio:0) {
            .slider {
                width: 100%;
                height: 5px;
                position: relative;
                direction: rtl;
                -webkit-appearance: none;
                background-color: var(--gray);
            }
            .slider::-webkit-slider-runnable-track {
                height: 100%;
                -webkit-appearance: none;
                color: transparent;
                margin-top: -10px;
               
            }

        }

        /** FF*/
        .slider::-moz-range-progress {
            background-color: var(--primary);
       
        }

        .slider::-moz-range-track {
            background-color: transparent;
       
        }

        /* IE*/
        .slider::-ms-fill-lower {
            background-color: var(--primary);
       
        }

        .slider::-ms-fill-upper {
       
            background-color: transparent;
        }

        .more {
            i {
                font-size: 26px;
            }
        }
    }

    .splide__slide {
        &.is-active {
            .artist-image {
                img {

                    z-index: 10000;
                    scale: 1.15;

                    @media (min-width:560px) {
                        scale: 1.05 !important;
                    }
                }
            }


        }
    }

    .footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 20px;

        i,
        a {
            color: var(--black);
            font-size: 25px;
        }
    }


    .artist-image {
        margin-top: 9vh;

        img {
            border-radius: 5px;
            width: calc(((100% + 35px) / 1) - 35px);
            height: 30vh;
        }
    }

    .content {
        margin-top: 5vh;

        .control-player {
            margin: 5vh 0;
        }
    }
}

.playlistModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: #000000aa;
    backdrop-filter: blur(10px);
    z-index: 12;
    color: #fff;
    padding: 20px;

    .HorizontalBoxImage {

        .name {
            color: #fff !important;
        }

        .artist {
            color: #999 !important;
        }
    }
}

.moreMusic {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70vh;
    z-index: 12;
    background: #000000aa;
    backdrop-filter: blur(15px);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
    color: #fff;
    overflow-y: scroll;

    header {
        img {
            width: 20vw;
            height: 20vw;
            border-radius: 10px;
        }

        font-size: 18px;

        .gray {
            font-size: 14px;
            color: #999;
        }

        padding-bottom: 25px;
        border-bottom: 1px solid #999;
    }

    .item {
        display: flex;
        align-items: center;

        margin: 30px 0;

        i {
            margin-left: 10px;
            font-size: 25px;
        }
    }
}