.searchPage {
    
    .searchheader {
        padding: 10px;
        width: 100%;
        background-color: var(--white);

        .searchinput {
            background-color: var(--light);
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 15px;
            position: relative;
            height: 40px;

            input {
                width: 80%;
                outline: none;
                border: none;
                background-color: transparent;
                padding: 10px;
                color: var(--black);
                position: absolute;
                z-index: 5;
                right: 0;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                i {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}