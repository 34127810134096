.item_music {

  width: 100%;
  padding: 0 8px;

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .lable_header {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;

      span {
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--primary);
        border-top-left-radius: 15px !important;
        border-radius: 5px;
        padding: 3px 10px;

        p {
          color: white;
          font-size: 13px;
        }
      }
    }


    .image_music {
      @media (max-width:1400px) {
        height: 255px !important;
      }

      @media (max-width:1200px) {
        height: 210px !important;
      }

      @media (max-width:991px) {
        height: 150px !important;
      }

      @media (max-width:768px) {
        height: 222px !important;
      }

      @media (max-width:767px) {
        height: 148px !important;
      }

      @media (max-width:575px) {
        height: 26.2vw !important;
      }


      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 250px;
      position: relative;

    }

    img {
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .icon_music {
      width: 100%;
      height: 100%;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: rgb(34, 34, 34, 0.5);
      border-radius: 15px;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
    }

    .svg {
      position: absolute;
      z-index: 5;
      display: none;
      bottom: 5px;
      left: 5px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .lable_footer {
      position: absolute;
      bottom: 0;
      right: 10px;
      padding: 5px 10px;
      z-index: 3;

      p {
        color: #fff;
      }
    }

  }

  .item_description {
    direction: rtl;
    padding: 0 12px;

    @media screen and (max-width:"768px") {
      a {
        font-size: 13px !important;
      }
    }

    a {
      color: var(--black);
      text-decoration: none;
      font-style: normal;
    }

    .name_music {

      span {
        display: block;
        color: var(--black);
        font-weight: 700;
        line-height: 28px;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .artist_music {

      a {
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: var(--black2);
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

}