.CategoryImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    a {
        position: relative !important;

        .img {
            object-fit: contain;
            transition: all ease .5s;
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }

        .lable {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            p {
                text-align: center;
                margin-top: 16px;
                margin-bottom: 0;
                color: #fff;
                font-size: 24px;
                font-weight: 500;
            }

            @media (max-width: 992px) {
                p {
                    font-size: 18px;
                }
            }

            @media (max-width: 576px) {
                p {
                    font-size: 16px;
                }
            }

            @media (max-width: 992px) {
                svg {
                    width: 55px;
                    height: 55px;
                }
            }

            @media (max-width: 400px) {
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}