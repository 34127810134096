.item {

    padding: 0px 0px;

    span {
        margin-right: 10px;
        font-size: 15px;
    }
}

.borderb {

    border-bottom: 2px solid var(--line);

    a {
        i {
            color: var(--black);
        }

        span {
            font-size: 17px;
        }

        div {
            align-items: center;
            padding: 8px 0;
        }
    }
}

.lighttext {
    font-size: 15px;
    color: var(--gray);
}

.goback {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    margin: 15px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: var(--ccc);
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 20px;
        color: var(--black);
    }
}

.mymusic {
    .head {
        background-color: var(--mymusicback);
        width: 100%;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
            margin-top: 14px;
            font-size: 17px;
        }

        .image {
            width: 50%;
            height: 50%;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 34%);
            ;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .btnscont {
        margin-top: -15px;

        .circlebtn {
            width: 37px;
            height: 37px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--black2);

            i {
                color: #fff;
                font-size: 12px;
            }
        }

        .palybtn {
            border-radius: 50px;
            background-color: var(--primary);
            border: none;
            outline: none;
            padding: 11px 25px;
            color: #fff;

            span {
                margin-right: 15px;
            }
        }
    }
}

.myMusic {
    .headerMyMusic {
        @media (max-width:768px) {
            justify-content: space-between;
            padding: 0 40px;
        }

        padding: 0 80px;
        justify-content: space-between;

        span {
            font-size: 15px;
            color: var(--black);

            i {
                font-size: 20px;
            }
        }
    }

    .listMusic {
        padding: 0 12px;

        .musicbox {
            display: flex;
            align-items: center;
            width: 100%;
            cursor: pointer;

            .avatar {
                .num {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        position: absolute;
                        opacity: 0;
                    }

                    span {
                        opacity: 1;
                        font-size: 20px;
                    }
                }
            }

            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .info {
                    @media (max-width:768px) {
                        display: grid;
                    }

                    display: flex;
                    width: 100%;

                    .name_music {
                        @media (max-width:768px) {
                            width: 100%;
                            font-size: 12px;
                        }

                        display: flex;
                        width: 50%;
                        padding: 0 20px;
                        font-size: 14px;
                        line-height: 24px;
                        color: var(--black);
                    }

                    .artict_music {
                        @media (max-width:768px) {
                            width: 100%;
                            font-size: 12px;
                        }

                        display: flex;
                        width: 50%;
                        padding: 0 20px;
                        font-size: 14px;
                        line-height: 24px;
                        color: var(--black);
                    }
                }

                .time {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        cursor: pointer;
                        font-size: 20px;
                        opacity: 0;

                        &:hover {
                            color: var(--primary);
                        }
                    }
                }
            }

            &:hover {
                background-color: var(--background);

                .avatar {
                    .num {
                        i {
                            opacity: 1;
                        }

                        span {
                            opacity: 0;
                        }
                    }
                }

                .time {
                    i {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.uplodFile {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 25px 20px;

    label {
        font-size: 12px;
        font-weight: 600;
        color: var(--black);
    }

    select {
        font-size: 14px;
        font-family: 'vazir';
        width: 100%;
        background: var(--white);
        padding: 10px;
        border: none;
        outline: none;
        height: 42px;
        border: 1px solid var(--gray);
        border-radius: 8px;
        color: var(--black);
        margin: 7px 0;

        option {
            font-size: 14px;
            font-family: 'vazir';
            width: 100%;
            background: var(--white);
            padding: 10px;
            border: none;
            outline: none;
            height: 42px;
            border: 1px solid var(--gray);
            border-radius: 8px;
            color: var(--black);
            margin: 7px 0;
        }
    }

    .input {
        font-size: 14px;
        font-family: 'vazir';
        width: 100%;
        background: transparent;
        padding: 10px;
        border: none;
        outline: none;
        height: 42px;
        border: 1px solid var(--gray);
        border-radius: 8px;
        color: var(--black);
        margin: 7px 0;
    }



    .file {
        margin: 7px 0;
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
        height: 150px;
        border: 1px solid var(--gray);
        border-radius: 8px;
        position: relative;
        overflow: hidden;

        p {
            color: var(--black);
            padding: 8px;
            font-size: small;
            font-weight: 600;
            position: absolute;
            top: 35%;
            right: 25%;
        }

        .image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        input {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
        }
    }

    .submit {
        margin: 50px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        button {
            border: none;
            outline: none;
            color: #fff;
            background-color: var(--primary);
            padding: 10px 35px;
            border-radius: 10px;
        }
    }
}