.Register {
    height: 100%;
    background-color: var(--black3);

    .Registercard {
        position: relative;
        height: 60vh;
        width: 100%;
        background: url('../../assets//images/backgroundRegister.jpg') no-repeat center;
        background-size: cover;

        .goback {
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            margin: 15px;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background: var(--ccc);
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 20px;
                color: var(--black);
            }
        }

        .gradients {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: var(--primaryGradient2);
        }
    }


    .Registerform {
        position: absolute;
        bottom: 10%;
        width: 100%;

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content {
            .title {
                margin-top: 10px;
                display: flex;
                align-items: center;
                flex-direction: column !important;
                width: 100%;
                color: var(--black);

                .titletext {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 32px;
                    text-align: center;
                    max-width: 75%;
                    margin: 0;
                }
            }

            .form {
                padding: 10px 35px;

                .input {
                    color: var(--black);
                    direction: rtl;
                }

                .error {
                    border: 1px solid var(--primary) !important;

                }

                .error ::placeholder {
                    color: var(--primary) !important;
                }

                .input {
                    border: 1px solid var(--gray);
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    input {
                        background: transparent !important;
                        font-size: 14px;
                        letter-spacing: 1px;
                        outline: none;
                        border: none;
                        padding: 14px 12px;
                        width: 100%;
                        color: var(--black) !important;
                    }

                    i {
                        font-size: 18px;
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }

                .forgetpass {
                    color: var(--black);
                    font-size: 10px;
                    margin-bottom: 12px;
                    cursor: pointer;
                }

                button {
                    padding: 8px 12px;
                    font-size: 16px;
                    font-weight: 600;
                    width: 100%;
                    border-radius: 8px;

                }

                .buttonone {
                    background: var(--primaryGradient) !important;
                    color: white !important;
                    border: none;
                    outline: none;
                    padding: 0.56rem 0;
                }

                .buttontwo {
                    padding: 8px 12px;
                    font-size: 16px;
                    font-weight: 600;
                    width: 100%;
                    border-radius: 8px;
                    margin: 20px 0 !important;
                    background: transparent;
                    color: var(--purple);
                    border: 1px solid var(--purple);
                }
            }
        }
    }



}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}