.videopage {

    .headerpage {
        padding: 30px 0 10px 0;

        .title {
            h3 {
                font-size: 17px;
                color: var(--black);
            }
        }

        .btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 7px;
            background-color: var(--background);
            border-radius: 5px;
            width: 200px;

            @media(max-width:425px) {
                width: 160px !important;
                padding: 3px !important;
            }

            button {
                width: 50%;
                padding: 10px 15px;
                border: none;
                outline: none;
                margin: 0 2px;
                border-radius: 5px;
                background: transparent;
                color: var(--black);
                font-size: 12px;

                @media(max-width:425px) {
                    padding: 7px 15px !important;
                }
            }

            .active {
                background: var(--primary);
                color: var(--white);
            }
        }
    }

    .content {

        .sizeBox {
            @media (max-width:769px) {
                width: calc(33.333333%);
            }

            @media (max-width:480px) {
                width: calc(50%);
            }

            width: calc(25%);
        }
    }

    .pagination {
        padding: 20px 0;

        button.active {
            background: var(--primary) !important;
            color: var(--white) !important;
        }

        .button {
            padding: 5px;
            margin: 0 5px;
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--background);
            border-radius: 10px;

            span {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .sizeBox {
        @media (max-width:769px) {
            width: calc(33.333333%);
        }

        @media (max-width:479px) {
            width: calc(50%);
        }

        width: calc(25%);
    }
}

.single_clip {
    .title {
        padding: 0 0 10px 0;
        font-size: 28px;
        line-height: 34px;
        font-style: normal;
        color: var(--black);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width:500px) {
            font-size: 24px;
        }

        @media (max-width:400px) {
            font-size: 20px;
        }

        @media (max-width:375px) {
            font-size: 16px;
        }
    }

    .listVideo {

        .contents {
            display: flex;
            flex-wrap: wrap;

            h2 {
                width: 100%;
                display: block;
                margin: 20px 0;
                font-size: 22px;
                color: var(--black);
            }
            .sizeBox {
                @media (max-width:769px) {
                    width: calc(33.333333%);
                }
        
                @media (max-width:479px) {
                    width: calc(50%);
                }
        
                width: calc(25%);
            }
        }
    }
}